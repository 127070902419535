import { twMerge } from "tailwind-merge";
import { navigate } from "gatsby";
import * as React from "react";

interface Card {
  to: string;
  className: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const Card: React.FC<Card> = ({
  to,
  className,
  onMouseEnter,
  onMouseLeave,
  children,
}) => {
  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={twMerge(
        "p-8 shadow-card rounded-2xl cursor-pointer hover-card",
        className
      )}
      onClick={() => navigate(to)}
    >
      {children}
    </div>
  );
};

export default Card;
