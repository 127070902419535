import * as React from "react";
import { useMediaQuery } from "react-responsive";

const SectionSeparator = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  if (!isDesktopOrLaptop) {
    return null;
  }

  return <div className="w-full my-12 border-b border-greySeparator" />;
};

export default SectionSeparator;
