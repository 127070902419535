import * as React from "react";
import { twMerge } from "tailwind-merge";
import Title, { TitleVariant } from "../Title";

interface SectionTitle {
  title: string;
  Image: React.ReactNode;
  className?: string;
}

const SectionTitle = ({ title, Image, className }: SectionTitle) => {
  const calulatedClassName = twMerge(
    "flex mx-2 justify-center lg:flex-col h-27 lg:h-auto",
    className
  );

  return (
    <div className={calulatedClassName}>
      <div className="my-auto lg:text-center">{Image}</div>
      <div className="flex flex-col items-center justify-between lg:mt-10">
        <span className="w-20 border-b border-greySeparator lg:invisible" />
        <Title
          text={title}
          variant={TitleVariant.h2}
          className="w-48 my-auto font-light text-center h-36"
        />
        <span className="w-20 border-b border-greySeparator lg:invisible" />
      </div>
    </div>
  );
};

export default SectionTitle;
