import * as React from "react";
// @ts-ignore no type available \o/
import ReactSlidy from "react-slidy";
import { twMerge } from "tailwind-merge";
import { StaticImage } from "gatsby-plugin-image";
import { Swipe } from "mirsahib-react-swipe-component";

import Quote from "../Quote";

const TestimonySlider = () => {
  const [actualSlide, setActualSlide] = React.useState(0);

  const updateSlide = ({ currentSlide }: { currentSlide: number }) => {
    setActualSlide(currentSlide);
  };

  const quotesClassNames = twMerge("mb-2 whitespace-normal mx-2 slider:mx-11");

  const quotes = [
    <Quote
      key="quote-florentin"
      className={quotesClassNames}
      picture={
        <StaticImage
          src="../../images/photos/florentin.jpg"
          alt="photo de Florentin"
          className="w-12 h-12 rounded-full lg:w-16 lg:h-16 "
        />
      }
      nameAndTitle={
        <p>
          Florentin Rodio
          <br />
          Lead UX Designer & ergonome senior chez Pôle emploi
        </p>
      }
      quote={
        <p className="italic">
          "Donut Panic est intervenu dans le cadre d’un de nos projets
          stratégiques impliquant une transformation très importante d’un de nos
          services clefs. Très impliqué, à l’écoute et toujours prêt à s’adapter
          au contexte très politique de notre structure, Quentin a su nous
          épauler et faire valoir son expertise. Doté d’une grande palette
          méthodologique, il a su s’adapter aux contraintes, contextes et
          spécificités des demandes du projet. Très empathique et chaleureux, ce
          fut un véritable plaisir de travailler ensemble."
        </p>
      }
    />,

    <Quote
      key="quote-sylvain"
      className={quotesClassNames}
      picture={
        <StaticImage
          src="../../images/photos/sylvain.png"
          alt="photo de Sylvain"
          className="w-12 h-12 rounded-full lg:w-16 lg:h-16 "
        />
      }
      nameAndTitle={
        <p>
          Sylvain Bruyère
          <br />
          PDG de Cadeau Maestro
        </p>
      }
      quote={
        <p className="inline italic">
          “Donut Panic a fait un travail remarquable dans le cadre de la refonte
          de notre site de e-commerce www.cadeau-maestro.com (Prestashop, + 3M€,
          300k clients).
          <br />
          <br />
          C’était indispensable pour nous d’avoir un 2ème œil sur le travail
          réalisé sur cette refonte, et son avis a été précieux. Quentin a su
          avoir une écoute bienveillante et intelligente de nos problématiques
          commerciales et de fidélisation, les prendre en compte ainsi que les
          spécificités de notre métier, et travailler de concert avec nous pour
          imaginer un outil plus efficace et plus simple à utiliser pour nos
          clients. De nombreuses reco ont déjà été mises en ligne, et d’autres
          sont en cours.
          <br />
          <br />
          Bref, merci à Donut Panic pour ce travail qui nous aidera clairement à
          mieux répondre à nos enjeux actuels.”
        </p>
      }
    />,

    <Quote
      key="quote-leslie"
      className={quotesClassNames}
      picture={
        <StaticImage
          src="../../images/photos/Leslie.png"
          alt="photo de Leslie"
          className="w-12 h-12 rounded-full lg:w-16 lg:h-16 "
        />
      }
      nameAndTitle={
        <p>
          Leslie Matte
          <br />
          Consultante et formatrice en Design d’expérience (DX)&nbsp;: Ergonome
          expert, UX Design et Design Thinking
        </p>
      }
      quote={
        <p className="italic">
          “Avec Quentin et Pierre, nous travaillons ensemble régulièrement sur
          des sujets tels que des optimisations UX ou des projets
          d’enseignements a l’école multimedia. C’est un réel plaisir à chaque
          fois de partager nos points de vue avec un professionnalisme qui
          séduit systématiquement les clients.”
        </p>
      }
    />,

    <Quote
      key="quote-sandrine"
      className={quotesClassNames}
      picture={
        <StaticImage
          src="../../images/photos/sandrine.png"
          alt="photo de Sandrine"
          className="w-12 h-12 rounded-full lg:w-16 lg:h-16 "
        />
      }
      nameAndTitle={
        <p>
          Sandrine Lavandier
          <br />
          Consultante Marketing digital & Directrice pédagogique à l’École
          Multimédia
        </p>
      }
      quote={
        <p className="italic">
          "Depuis plusieurs années je travaille avec Pierre Minelli et Quentin
          Ledoux, dans le cadre de leur fonction de professeurs référents de la
          filière UX à l’Ecole Multimédia. Cette collaboration est, sous
          plusieurs aspects, très stimulante et enrichissante. Outre leur
          savoir-faire et connaissances indéniables dans leur domaine, ils sont
          aussi force de proposition et pourvoyeurs de solutions. Ils
          m’accompagnent régulièrement dans les réflexions à mener pour garantir
          à nos apprenants le meilleur enseignement et suivi possible. Toujours
          dans un objectif commun de s’améliorer en permanence, ils sont une
          vraie valeur ajoutée pour l’école."
        </p>
      }
    />,

    <Quote
      key="quote-romain"
      className={quotesClassNames}
      picture={
        <StaticImage
          src="../../images/photos/romain.png"
          alt="photo de Romain"
          className="w-12 h-12 rounded-full lg:w-16 lg:h-16 "
        />
      }
      nameAndTitle={
        <p>
          Romain Nauthonnier
          <br />
          Cofondateur et PDG de COMBIGO
        </p>
      }
      quote={
        <p className="italic">
          “Lors de leur projet de fin d’étude, les étudiants de la formation
          « UX designer » de l’école multimédia dont Pierre et Quentin sont
          référents ont fait preuve d’un grand professionnalisme en produisant
          un travail de qualité, répondant aux enjeux de design UX complexes de
          notre plateforme de recherche de billets d’avion et de train.
          <br />
          <br />
          Nous avons particulièrement apprécié la méthodologie inculquée aux
          étudiants, centrée sur l’utilisateur avec une prise en compte de ses
          dimensions psychologique et émotionnelle qui s’est concrétisée par de
          nombreuses recherches et ateliers.”
        </p>
      }
    />,
  ];

  const handleClick = () =>
    updateSlide({
      currentSlide: getNextSlideNumber(actualSlide + 1),
    });

  const getNextSlideNumber = (number: number) => {
    const lastSlideNumber = quotes.length - 1;

    if (number < 0) {
      return lastSlideNumber;
    }

    if (number > lastSlideNumber) {
      return 0;
    }

    return number;
  };

  const handleSwipe = (direction: "right" | "left") =>
    updateSlide({
      currentSlide: getNextSlideNumber(
        direction === "left" ? actualSlide + 1 : actualSlide - 1
      ),
    });

  return (
    <>
      <div onClick={handleClick}>
        <Swipe
          detectTouch
          onSwipedLeft={() => handleSwipe("left")}
          onSwipedRight={() => handleSwipe("right")}
          className="relative"
        >
          <ReactSlidy
            doAfterSlide={updateSlide}
            slide={actualSlide}
            infiniteLoop
          >
            {quotes}
          </ReactSlidy>
          <div className="absolute top-0 left-0 flex flex-1 w-full h-full slider:hidden " />
        </Swipe>
      </div>
      <div className="flex justify-center Dots">
        {quotes.map((_, index) => {
          return (
            <button
              key={index}
              className={twMerge(
                "bg-transparent border-none cursor-pointer text-3xl",
                index === actualSlide ? "text-gray-800" : "text-gray-400"
              )}
              onClick={() => updateSlide({ currentSlide: index })}
            >
              &bull;
            </button>
          );
        })}
      </div>
    </>
  );
};

export default TestimonySlider;
