import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import donutAnimeP1 from "../images/icons/illustration-p1.gif";
import donutAnimeP2 from "../images/icons/illustration-p2.gif";
import donutAnimeP3 from "../images/icons/illustration-p3.gif";
import donutAnimeP4 from "../images/icons/illustration-p4.gif";

import Button, { ButtonVariant } from "../components/Button";
import Layout from "../components/Layout";
import Title, { TitleVariant } from "../components/Title";
import Card from "../components/Card/Card";
import BlogSummary from "../components/BlogSummary";
import { useLayout } from "../components/Layout/layout-context";
import Section from "../components/Section";
import CustomerList from "../components/CustomerList";
import TestimonySlider from "../components/TestimonySlider";
import SectionTitle from "../components/SectionTitle";
import SectionSeparator from "../components/SectionSeparator";

// markup
const IndexPage = () => {
  const [setShowModal] = useLayout();

  const [animatedImage, setAnimatedImage] = React.useState("none");

  const leaveCard = (cardName: string) => {
    setAnimatedImage(animatedImage === cardName ? "none" : cardName);
    // setTimeout(
    //   () => setAnimatedImage(animatedImage === cardName ? "none" : cardName),
    //   1000
    // );
  };

  return (
    <Layout
      title="Développez votre business grâce à l'expérience de vos utilisateurs"
      metaTitle="Design d'expérience responsable"
      description="Agence de design responsable, nous vous aidons à optimiser vos parcours clients et à offrir une expérience unique"
    >
      {/* pourquoi nous ? */}
      <Section title="Pourquoi faire appel à nous ?">
        <div className="flex flex-col mt-main lg:flex-row">
          <div className="flex flex-row items-center h-20 lg:flex-col lg:p-4 lg:h-auto">
            <div className="w-12 mr-4 shrink-0 lg:mr-0 lg:w-20">
              <StaticImage src="../images/icons/list-icon-1.png" alt="-" />
            </div>
            <p className="lg:mt-4">
              Ici pas de chichi, l'expérience utilisateur vous guide pour
              développer votre business
            </p>
          </div>
          <div className="my-2 border-b lg:flex lg:border-b-0 lg:mx-2 lg:my-0 lg:border-l lg:flew-grow"></div>
          <div className="flex flex-row items-center h-20 lg:flex-col lg:p-4 lg:h-auto">
            <div className="w-12 mr-4 shrink-0 lg:mr-0 lg:w-20">
              <StaticImage src="../images/icons/list-icon-2.png" alt="-" />
            </div>
            <p className="lg:mt-4">
              Nous engageons notre temps pour connecter et accompagner les
              designers à améliorer leur pratique
            </p>
          </div>
          <div className="my-2 border-b lg:flex lg:border-b-0 lg:mx-2 lg:my-0 lg:border-l lg:flew-grow"></div>
          <div className="flex flex-row items-center h-20 lg:flex-col lg:p-4 lg:h-auto">
            <div className="w-12 mr-4 shrink-0 lg:mr-0 lg:w-20">
              <StaticImage src="../images/icons/list-icon-3.png" alt="-" />
            </div>
            <p className="lg:mt-4">
              Chaque prestation vous permet de soutenir les initiatives
              communautaires de Donut Panic
            </p>
          </div>
        </div>
        <Button
          text="Contactez-nous"
          variant={ButtonVariant.primary}
          className="mx-auto mt-10"
          onClick={() => setShowModal(true)}
        />
      </Section>
      {/* nos prestations */}
      <Section title="Nos prestations pour votre entreprise">
        <div className="flex flex-wrap justify-center">
          <Card
            className="relative flex flex-col justify-between max-w-xs mx-5 mt-main lg:max-w-sm"
            to="/creer-mon-site"
            onMouseEnter={() => setAnimatedImage("creation")}
            onMouseLeave={() => leaveCard("creation")}
          >
            <div>
              <div className="text-center">
                {animatedImage === "creation" ? (
                  <img
                    src={donutAnimeP3}
                    alt="donut animé"
                    className="mx-auto w-36 h-36"
                  />
                ) : (
                  <StaticImage
                    alt="donut soupoudré 3"
                    src="../images/icons/illustration-p3.png"
                    height={144}
                    width={144}
                  />
                )}
              </div>
              <Title
                variant={TitleVariant.h3}
                text="Création de votre nouveau produit"
                className="mt-8 text-center"
              />
              <p className="mt-8">
                Nous vous accompagnons dans la conception de votre nouveau
                produit digital, selon des critères de qualité web rigoureux.
                Afin de vous permettre d'atteindre vos objectifs et de
                satisfaire les besoins de vos clients.
              </p>
            </div>

            <Button
              text="Concevoir"
              variant={ButtonVariant.secondary}
              to="/creer-mon-site"
              className="mx-auto mt-8"
            />
          </Card>

          <Card
            className="relative flex flex-col justify-between max-w-xs mx-5 mt-main lg:max-w-sm"
            to="/optimiser-son-parcours-utilisateur"
            onMouseEnter={() => setAnimatedImage("optimisation")}
            onMouseLeave={() => leaveCard("optimisation")}
          >
            <div>
              <div className="text-center">
                {animatedImage === "optimisation" ? (
                  <img
                    src={donutAnimeP2}
                    alt="donut animé"
                    className="mx-auto w-36 h-36"
                  />
                ) : (
                  <StaticImage
                    alt="donut soupoudré 2"
                    src="../images/icons/illustration-p2.png"
                    height={144}
                    width={144}
                  />
                )}
              </div>
              <Title
                variant={TitleVariant.h3}
                text="Optimisation de vos parcours clients"
                className="mt-8 text-center"
              />
              <p className="mt-8">
                Le cheminement emprunté par les utilisateurs de votre site a
                toute son importance dans l’élaboration de la stratégie de
                contenu. Nous étudions toutes les données à notre disposition
                afin de définir le parcours le plus pertinent pour votre
                objectif.
              </p>
            </div>
            <Button
              text="Optimiser"
              variant={ButtonVariant.secondary}
              to="/optimiser-son-parcours-utilisateur"
              className="mx-auto mt-8"
            />
          </Card>

          <Card
            className="relative flex flex-col justify-between max-w-xs mx-5 mt-main lg:max-w-sm"
            to="/refondre-mon-site"
            onMouseEnter={() => setAnimatedImage("refonte")}
            onMouseLeave={() => leaveCard("refonte")}
          >
            <div>
              <div className="text-center">
                {animatedImage === "refonte" ? (
                  <img
                    src={donutAnimeP1}
                    alt="donut animé"
                    className="mx-auto w-36 h-36"
                  />
                ) : (
                  <StaticImage
                    alt="donut soupoudré 1"
                    src="../images/icons/illustration-p1.png"
                    height={144}
                    width={144}
                  />
                )}
              </div>
              <Title
                variant={TitleVariant.h3}
                text="Refonte de votre produit"
                className="mt-8 text-center"
              />
              <p className="mt-8">
                Après une analyse de votre produit actuel, nous vous proposons
                des axes d’amélioration de l’expérience client pour vous
                permettre d’atteindre vos objectifs.
              </p>
            </div>
            <Button
              text="Refondre"
              variant={ButtonVariant.secondary}
              to="/refondre-mon-site"
              className="mx-auto mt-8"
            />
          </Card>

          <Card
            className="relative flex flex-col justify-between max-w-xs mx-5 mt-main lg:max-w-sm"
            to="/formations"
            onMouseEnter={() => setAnimatedImage("formation")}
            onMouseLeave={() => leaveCard("formation")}
          >
            <div>
              <div className="text-center">
                {animatedImage === "formation" ? (
                  <img
                    src={donutAnimeP4}
                    alt="donut animé"
                    className="mx-auto w-36 h-36"
                  />
                ) : (
                  <StaticImage
                    alt="donut soupoudré 4"
                    src="../images/icons/illustration-p4.png"
                    height={144}
                    width={144}
                  />
                )}
              </div>
              <Title
                variant={TitleVariant.h3}
                text="Formation"
                className="mt-8 text-center"
              />
              <p className="mt-8">
                Quelques heures ou quelques jours vous permettront de monter en
                compétences et d'atteindre vos objectifs. Nous souhaitons
                accompagner des experts sur les compétences qui feront d'eux des
                professionnels incontournables dans leurs secteurs dès demain.
              </p>
            </div>
            <Button
              text="Vous former"
              variant={ButtonVariant.secondary}
              to="/formations"
              className="mx-auto mt-8"
            />
          </Card>
        </div>
      </Section>
      {/* l'agence */}
      <section className="py-4 mt-secondary">
        <SectionTitle
          title="L'agence"
          Image={
            <StaticImage
              alt="donut magasin"
              src="../images/icons/donut-home.png"
              className="w-24 lg:w-32"
            />
          }
        />

        <p className="mt-main">
          Découvrez notre DonuTeam, l’histoire de Donut Panic et les valeurs qui
          nous sont chères ! <br />
          <br />
          Nous sommes une équipe de choc de collaborateurs survitaminés
          travaillant également avec des prestataires externes pour compléter
          nos services. Notre agence se caractérise par un travail rigoureux
          couplé à une dose de fun injectée dans chacun de nos projets et dans
          nos relations aux autres.
        </p>
        <div className="flex justify-between mt-main lg:mx-18">
          <div className="relative flex items-center justify-center lg:w-28 lg:h-28 h-18 w-18">
            <div className="absolute w-full h-full">
              <StaticImage alt="bg2" src="../images/photos/circle2.png" />
            </div>
            <StaticImage
              alt="photo de quentin"
              src="../images/photos/quentin.jpg"
              className="m-2 rounded-full"
            />
          </div>
          <div className="relative flex items-center justify-center lg:w-28 lg:h-28 h-18 w-18">
            <div className="absolute w-full h-full">
              <StaticImage alt="bg3" src="../images/photos/circle3.png" />
            </div>
            <StaticImage
              alt="photo de pierre"
              src="../images/photos/pierre.jpg"
              className="m-2 rounded-full"
            />
          </div>
          <div className="relative flex items-center justify-center lg:w-28 lg:h-28 h-18 w-18">
            <div className="absolute w-full h-full">
              <StaticImage alt="bg4" src="../images/photos/circle4.png" />
            </div>
            <StaticImage
              alt="photo de tara"
              src="../images/photos/tara.jpg"
              className="m-2 rounded-full"
            />
          </div>
        </div>
        <Button
          text="Découvrir l'agence"
          variant={ButtonVariant.secondary}
          to="/agence"
          className="mx-auto mt-main"
        />
        <p className="mt-main">
          Travailler ensemble c’est s’engager dans une perspective qui respecte
          les valeurs de chacun. Nous vous dévoilons les aspects humains que
          nous revendiquons, qui guident notre démarche.
        </p>
      </section>

      <div className="self-center my-10 border-b border-graySeparator"></div>

      {/* les témoignages */}
      <section className="py-4">
        <TestimonySlider />
      </section>
      <SectionSeparator />

      {/* clients */}
      <section className="py-4 lg:py-8">
        <SectionTitle
          Image={
            <StaticImage
              alt="donut mongolfière"
              src="../images/icons/donut-balloon.png"
              className="w-24 lg:w-32"
            />
          }
          title="Ils nous font confiance"
        />

        <CustomerList className="mt-main" />
        <Button
          text="Voir tous nos clients"
          variant={ButtonVariant.secondary}
          to="/clients"
          className="mx-auto mt-main"
        />
      </section>

      {/* le blog */}
      {false && (
        <>
          <SectionSeparator />

          <section className="py-4 mt-secondary">
            <SectionTitle
              title="Le blog"
              Image={
                <StaticImage
                  alt="donut crayon"
                  src="../images/icons/donut-pen.png"
                  className="w-24 lg:w-32"
                />
              }
            />

            <BlogSummary className="mt-main" />
            <Button
              text="Lire les articles"
              variant={ButtonVariant.secondary}
              to="/blog"
              className="mx-auto mt-main"
            />
          </section>
        </>
      )}
    </Layout>
  );
};

export default IndexPage;
